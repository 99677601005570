<template>
  <div class="checkbox flex align-start justify-start">
    <input
      type="checkbox"
      value=""
      :id="id"
      :checked="checked"
      :class="{ readOnly: readOnly, disabled: disabled }"
      :disabled="disabled || readOnly"
    />
    <label class="title-gray" :for="id"><slot/></label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: [
    'checked',
    'readOnly',
    'id',
    'class',
    'disabled'
  ],
  created () {
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
