<template>
  <div class="product">
    <div class="flex align-center justify-between mg-b-15">
      <div class="flex align-center">
        <h2 class="mg-r-15">{{$t('storelist.management')}}</h2>
      </div>
    </div>
    <form @submit.prevent="searchItems" class="product__search flex align-center justify-start mg-b-15 mg-t-15 pd-15">
      <div class="form-element mg-r-15">
        <input type="text" v-model="query" :placeholder="$t('global.search')" class="form-control"/>
      </div>
      <label for="service">{{$t('storelist.search_service')}}</label>
      <div class="form-element mg-r-15 mg-l-15">
        <select v-model="query_service" id="service" class="form-control">
          <option v-for="(item) in services" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
      <label for="status">{{$t('storelist.search_status')}}</label>
      <div class="form-element mg-r-15 mg-l-15">
        <select v-model="query_status" id="status" class="form-control">
          <option value="3">{{ $t('global.pending') }}</option>
          <option value="2">{{ $t('global.approved') }}</option>
          <option value="1">{{ $t('global.rejected') }}</option>
        </select>
      </div>
      <input type="checkbox" id="msp" v-model="query_msp">
      <label class="mg-r-15 mg-l-15" for="msp">{{$t('storelist.msp')}}</label>
      <button type="submit" class="btn btn--red">{{$t('global.search_btn')}}</button>
      <button type="button" class="btn btn--green mg-l-15" @click.prevent="resetSearch">{{$t('global.reset_btn')}}</button>
    </form>
    <div class="table" :class="{'is-operator': isOperator}">
      <div class="table__head">
        <div class="w-50 flex align-center justify-start">
          <Checkbox :checked="checkAll" @change="toggleCheckbox"/>
          {{$t('global.no')}}
        </div>
        <div class="w-200">{{$t('storelist.name')}}</div>
        <div class="w-125">{{$t('storelist.id')}}</div>
        <div class="w-125">{{$t('storelist.business')}}</div>
        <div class="w-125">{{$t('storelist.status')}}</div>
        <div v-if="!isOperator" class="w-200">{{$t('storelist.rate')}}</div>
<!--        <div class="w-200">{{$t('storelist.homepage')}}</div>-->
        <div class="w-200">{{$t('storelist.summary')}}</div>
        <div class="w-50">{{$t('global.actions')}}</div>
      </div>
      <div v-for="(item, index) in list" :key="index" class="table__line">
        <div class="w-50 flex align-center justify-start">
          <Checkbox :checked="item.selected" @change="list[index].selected = !list[index].selected"/>
          {{ index + 1 }}
        </div>
        <div class="w-200">{{item.company}}</div>
        <div class="w-125">{{item.merchant_id}}</div>
        <div class="w-125">{{item.service_name}}</div>
        <div class="w-125 flex align-center justify-start">
          <div class="dot mg-r-10" :class="{
            error: item.status === 1,
            warning: item.status === 3,
            success: item.status === 2
          }"/>
          <div>{{item.status === 3 ? $t('global.pending') : (item.status === 2 ? $t('global.approved') : $t('global.rejected'))}}</div>
        </div>
        <div v-if="!isOperator" class="w-200 flex align-center justify-start">
          <div class="flex-50 form-element">
            <input type="number" v-model="item.commission" class="form-control"/>
          </div>
          <button class="flex-50 mg-l-10 mg-r-20 btn btn--green round" @click="saveProduct(index)">{{$t('global.save')}}</button>
        </div>
<!--        <div class="w-200 flex align-center justify-start">-->
<!--          <div class="flex-50 form-element">-->
<!--            <input type="text" step="1" min="1" max="33" v-model="item.homepage" class="form-control"/>-->
<!--          </div>-->
<!--          <button class="flex-50 mg-l-10 mg-r-20 btn btn&#45;&#45;green round" @click="saveProduct(index)">{{$t('global.save')}}</button>-->
<!--        </div>-->
        <div class="w-200 flex align-center justify-start">
          <div class="flex-50 form-element">
            <input type="number"  v-model="item.summary_page" class="form-control"/>
          </div>
          <button class="flex-50 mg-l-10 mg-r-20 btn btn--green round" @click="saveProduct(index)">{{$t('global.save')}}</button>
        </div>
        <div class="actions w-50">
          <router-link :to="`merchants/${item.id}`" class="btn btn--orange" target="_blank"><i class="fas fa-eye"/></router-link>
          <router-link :to="`merchants/${item.id}/edit`" class="btn btn--green" target="_blank"><i class="fas fa-pen"/></router-link >
        </div>
      </div>
    </div>
    <div class="flex justify-center mg-t-20" v-if="hasMore">
      <button class="btn btn--red" @click.prevent="loadMerchants">{{$t('storelist.load_more')}}</button>
    </div>
    <Loading v-if="isLoading"/>
  </div>
</template>

<script>

import Checkbox from '@/components/checkbox/checkbox'
import Loading from '@/components/loading/Loading'
import { Merchant, Settings, Store } from '@/api/api'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderList',
  components: {
    Loading,
    Checkbox
  },
  data () {
    return {
      isLoading: true,
      page: 1,
      query: '',
      query_service: '',
      query_status: '',
      query_msp: false,
      hasMore: true,
      checkAll: false,
      list: [],
      services: []
    }
  },
  computed: {
    ...mapGetters({
      isOperator: 'auth/isOperator'
    })
  },
  mounted () {
    this.loadMerchants()
  },
  methods: {
    searchItems () {
      this.page = 1
      this.list = []

      this.loadMerchants()
    },
    resetSearch () {
      this.query = null
      this.query_status = null
      this.query_service = null
      this.query_msp = false
      this.page = 1
      this.list = []
      this.loadMerchants()
    },
    toggleCheckbox () {
      this.list = this.list.map((i) => {
        i.selected = !this.checkAll

        return i
      })

      this.checkAll = !this.checkAll
    },
    async loadMerchants () {
      this.isLoading = true

      try {
        const [merchant, services] = await Promise.all([
          Merchant.adminAll({
            page: this.page,
            search_term: this.query,
            service_id: this.query_service,
            status_id: this.query_status,
            msp_only: this.query_msp
          }),
          Settings.getServices()
        ])

        services.data = services.data.filter(service => service.is_web_active == true)

        this.services = services.data

        merchant.data.data = merchant.data.data.map((m) => {
          const service = services.data.find((s) => s.id === m.service.id)

          m.service_name = service ? service.name : '-'

          return m
        })

        const list = [
          ...this.list,
          ...merchant.data.data
        ]

        if (list.length >= merchant.data.total) {
          this.hasMore = false
        }

        this.page = this.page + 1
        this.list = [...list]
      } catch (err) {
        this.$toast.error(this.$t('storelist.load_fail'))
      } finally {
        this.isLoading = false
      }
    },
    async saveProduct (index) {
      this.isLoading = true

      try {
        delete this.list[index].features
        await Store.updateOne(this.list[index].id, this.list[index])

        this.$toast.success(this.$t('storelist.saved'))
      } catch (err) {
        this.$toast.error(this.$t('storelist.save_fail'))
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
